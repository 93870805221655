import { catchError, Observable, ObservableInput, of, OperatorFunction, ReplaySubject, share, startWith, Subject, tap } from 'rxjs';

export interface RmaLetData<T> extends Record<string, ObservableInput<boolean | string | T | null | undefined>> {
  loading$: Observable<boolean>;
  complete$: Observable<boolean>;
  error$: Observable<string | null | undefined>; // :shrug: if you remove undefined, the startWith fails
  result$: Observable<T | null>;
}

export function createRmaLetData<T, U>(data$: Observable<U>, getDataOperator: OperatorFunction<U, T>): RmaLetData<T>;

export function createRmaLetData<T>(input$: Observable<T>): RmaLetData<T>;

export function createRmaLetData<T, U>(data$: Observable<T | U>, operator?: OperatorFunction<U | T, T>): RmaLetData<T | U> {
  const loadingSubject = new ReplaySubject<boolean>(1);
  const completeSubject = new ReplaySubject<boolean>(1);
  const errorSubject = new Subject<string | null>();

  //Is this observable intended to emit multiple times
  const multi = !!operator;

  const result$ = !multi
    ? data$.pipe(share({ connector: () => new ReplaySubject(1) }))
    : data$.pipe(
        tap(() => loadingSubject.next(true)),
        operator,
        share({ connector: () => new ReplaySubject(1) }),
      );

  if (!multi) {
    loadingSubject.next(true);
  }

  result$.subscribe({
    error: (e: string | Error) => {
      const message = typeof e === 'string' ? e : e.message;
      errorSubject.next(message);
      loadingSubject.next(false);
    },
    next: () => {
      errorSubject.next(null);
      loadingSubject.next(false);
    },
    complete: () => {
      loadingSubject.next(false);
      completeSubject.next(true);
      errorSubject.next(null);
    },
  });

  return {
    complete$: completeSubject.asObservable().pipe(startWith(false)),
    error$: errorSubject.asObservable().pipe(startWith(null)),
    loading$: loadingSubject.asObservable(),
    result$: result$.pipe(catchError(() => of(null))),
  };
}
